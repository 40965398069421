<template>
  <div>
    <v-select class="pa-0" prepend-icon="mdi-calendar-range" :menu-props="{ offsetY: true }" selectedIndex="3" label="Zeitspanne" :items="timeranges" item-value="id" @input="updateDates" @click:prepend="dateDialog = true" :suffix="amountRangeString" v-model="dateIndex" hide-details single-line></v-select>

    <v-dialog v-model="dateDialog" ref="dialog" persistent width="300" :return-value.sync="newDates">
      <v-date-picker v-model="tmpDialogDates" range first-day-of-week="1" scrollable :selected-items-text="amountOfRange(tmpDialogDates)">
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="dateDialog = false"> Abbrechen </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(tmpDialogDates)"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';

moment.defaultFormat = 'YYYY-MM-DD';

export default {
  props: {
    dates: {
      type: Array,
      required: true,
    },
  },
  created() {
    if (this.timeranges[this.dateIndex].custom) {
      if (this.dates.length === 2) {
        this.newDates = this.dates;
      } else {
        this.updateDates(0);
      }
    } else {
      this.updateDates(this.dateIndex);
    }
  },
  data: () => ({
    // prettier-ignore
    timeranges: [
      { text: 'Heute', id: 0, dateFunction: () => [moment().format(), moment().format()] },
      { text: 'Gestern', id: 1, dateFunction: () => [moment().subtract(1, 'days').format(), moment().subtract(1, 'days').format()] },
      { text: 'Letzte 7 Tage', id: 2, dateFunction: () => [moment().subtract(6, 'days').format(), moment().format()] },
      { text: 'Letzte 14 Tage', id: 3, dateFunction: () => [moment().subtract(13, 'days').format(), moment().format()] },
      { text: 'Dieser Monat', id: 4, dateFunction: () => [moment().startOf('month').format(), moment().format()] },
      { text: 'Letzter Monat', id: 5, dateFunction: () => [moment().subtract(1, 'month').startOf('month').format(), moment().subtract(1, 'months').endOf('month').format()] },
      { text: 'Letzte 90 Tage', id: 6, dateFunction: () => [moment().subtract(90, 'days').format(), moment().format()] },
      { text: 'Letzte 6 Monate', id: 7, dateFunction: () => [moment().subtract(6, 'month').format(), moment().format()] },
      { text: 'Letzte 12 Monate', id: 8, dateFunction: () => [moment().subtract(12, 'month').format(), moment().format()] },
      { text: 'Dieses Jahr', id: 9, dateFunction: () => [moment().startOf('year').format(), moment().format()] },
      { text: 'Letztes Jahr', id: 10, dateFunction: () => [moment().subtract(1, 'year').startOf('year').format(), moment().subtract(1, 'year').endOf('year').format()] },
      { text: 'Letzte 2 Jahre', id: 11, dateFunction: () => [moment().subtract(2, 'year').startOf('year').format(), moment().format()] },
      { text: 'Letzte 3 Jahre', id: 12, dateFunction: () => [moment().subtract(3, 'year').startOf('year').format(), moment().format()] },
      { text: 'Benutzerdefinierter Zeitraum', id: 99, custom: true },
    ],
    dateDialog: false,
    dialogDates: [],
    tmpDialogDates: [],
    newDates: [],
  }),
  watch: {
    selectedPreset() {
      if (this.selectedPreset !== undefined) {
        this.newDates = this.timeranges[this.selectedPreset].arrayFun();
      }
    },
    menu() {
      if (!this.menu) this.$emit('update:dates', this.datesSorted);
    },
    newDates() {
      if (this.newDates.length === 2) {
        this.$emit('update:dates', this.datesSorted);
      }
    },
  },
  computed: {
    dateIndex: {
      get() {
        return this.$store.state.dateIndex;
      },
      set(state) {
        this.$store.commit('setDateIndex', state);
      },
    },
    datesSorted() {
      return [...this.newDates].sort();
    },
    amountRangeString() {
      return this.amountOfRange(this.datesSorted);
    },
  },
  methods: {
    updateDates(index) {
      if (typeof this.timeranges[index].dateFunction === 'function') {
        this.newDates = this.timeranges[index].dateFunction();
      } else {
        this.dateDialog = true;
      }
    },
    amountOfRange(dateArray) {
      const range = moment(new Date(dateArray[1])).diff(new Date(dateArray[0]));

      if (isNaN(range)) {
        return '';
      }
      let rangeString = moment.duration(range).add(1, 'day').humanize();
      rangeString = rangeString.charAt(0).toUpperCase() + rangeString.slice(1);
      return rangeString;
    },
  },
};
</script>
