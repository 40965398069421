<template>
  <v-container class="white d-flex flex-wrap py-0" rounded fluid>
    <the-store-chooser class="flex-grow-1 flex-shrink-0 mr-auto pa-2"></the-store-chooser>
    <the-date-range-picker class="flex-grow-1 flex-shrink-1 mr-auto pa-2" :dates.sync="dates" @update:dates="$emit('update:dates', $event)"></the-date-range-picker>
    <the-metric-type-selector v-if="!withoutUmsatz" class="flex-grow-1 flex-shrink-1 mr-auto pa-2" @update:metricItem="$emit('update:metricItem', $event)"></the-metric-type-selector>
  </v-container>
</template>

<script>
import TheDateRangePicker from '@/components/TheDateRangePicker.vue';
import TheStoreChooser from '@/components/TheStoreChooser.vue';
import TheMetricTypeSelector from '@/components/TheMetricTypeSelector.vue';

export default {
  components: {
    TheDateRangePicker,
    TheStoreChooser,
    TheMetricTypeSelector,
  },
  props: {
    withoutUmsatz: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dates: [],
  }),
};
</script>
