<template>
  <v-select v-model="selectedStores" item-text="Name" item-value="Oid" :items="stores" label="Stores filtern" single-line prepend-icon="mdi-store" ref="select" @click:prepend="focusSelect" clearable multiple hide-details :menu-props="{ offsetY: true }">
    <template v-slot:selection="{ item, index }">
      <v-chip small v-if="index <= 6" v-text="item.Name"></v-chip>
      <v-chip small v-if="index === 7" outlined color="primary">
        <v-icon left> mdi-plus-box-multiple </v-icon>
        {{ selectedStores.length - 7 }} weitere
      </v-chip>
    </template>
  </v-select>
</template>

<script>
export default {
  computed: {
    selectedStores: {
      get() {
        return this.$store.state.selectedStores;
      },
      set(state) {
        this.$store.commit('setSelectedStores', state);
      },
    },
    stores() {
      return this.$store.state.stores;
    },
  },
  methods: {
    focusSelect() {
      this.$refs.select.isMenuActive = !this.$refs.select.isMenuActive;
      this.$refs.select.isFocused = !this.$refs.select.isFocused;
    },
  },
};
</script>
