<template>
  <v-select v-model="selectedType" :items="types" label="Typ" prepend-icon="mdi-set-right" hide-details single-line ref="select" @click:prepend="focusSelect" :menu-props="{ offsetY: true }"></v-select>
</template>

<script>
export default {
  created() {
    this.selectedType = 0;
  },
  data: () => ({
    types: [
      { text: 'Umsatz', value: 0, item: 'totalGross', isCurrency: true },
      { text: 'Bestellanzahl', value: 1, item: 'orderCount', isCurrency: false },
    ],
    selectedType: -1,
  }),
  watch: {
    selectedType() {
      const ref = this.types[this.selectedType];
      this.$emit('update:metricItem', { item: ref.item, isCurrency: ref.isCurrency });
    },
  },
  methods: {
    focusSelect() {
      this.$refs.select.isMenuActive = !this.$refs.select.isMenuActive;
      this.$refs.select.isFocused = !this.$refs.select.isFocused;
    },
  },
};
</script>
