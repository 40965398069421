<template>
  <div>
    <the-option-bar :metricItem.sync="itemKey" :dates.sync="dates" :withoutUmsatz="true" @update:dates="$emit('update:dates', $event)"></the-option-bar>
    <base-divider></base-divider>
    <v-container class="white" fluid rounded>
      <v-data-table :headers="tableHeaders" :items="tableData" :loading="loading" :item-class="itemRowBackground" :items-per-page="25" dense>
        <template #item.orderId="{ item }">
          <div style="cursor: pointer" @click="() => $router.push({ name: 'OrderInfo', params: { id: item.orderId } })">#{{ item.orderId.slice(0, 7) }}</div>
        </template>

        <template #item.total="{ item }">
          {{ toCurrencyString(parseFloat(item.total)) }}
        </template>

        <template #item.deliveryDate="{ item }">
          {{ item.deliveryDate ? formateDate(item.deliveryDate) : 'Sofort' }}
        </template>
        <template v-for="slot in ['orderDate']" #[`item.${slot}`]="{ item }">
          {{ formateDate(item.orderDate) }}
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import ValueParseMixin from '@/mixins/ValueParseMixin.vue';
import BaseDivider from '@/components/BaseDivider.vue';
import TheOptionBar from '@/components/TheOptionBar.vue';

export default {
  mixins: [ValueParseMixin],
  components: {
    BaseDivider,
    TheOptionBar,
  },
  data: () => ({
    tableHeaders: [
      { text: 'Bestellnummer', value: 'orderId' },
      { text: 'Email', value: 'email' },
      { text: 'Vorname', value: 'firstname' },
      { text: 'Nachname', value: 'lastname' },
      { text: 'Bestellzeitpunkt', value: 'orderDate' },
      { text: 'Lieferzeitpunkt', value: 'deliveryDate' },
      { text: 'Telefon', value: 'phone' },
      { text: 'Straße', value: 'street' },
      { text: 'PLZ', value: 'postcode' },
      { text: 'Stadt', value: 'city' },
      { text: 'Gesamtbetrag', value: 'total', align: 'end' },
    ],
    tableData: [],
    orderCount: 0,
    itemKey: {},
    loading: false,
  }),
  computed: {
    stores() {
      return this.$store.state.stores;
    },
    selectedStores() {
      return this.$store.state.selectedStores;
    },
    dates: {
      get() {
        return this.$store.state.dates;
      },
      set(dates) {
        this.$store.commit('setDates', dates);
      },
    },
  },
  watch: {
    async dates() {
      this.loading = true;
      const response = await axios({
        method: 'post',
        url: '/order/monitor-data',
        baseURL: this.$store.state.storeItems[0].ApiUrl || process.env.VUE_APP_SHOP_API_BASEURL,
        data: {
          from: new Date(this.dates[0]),
          to: new Date(this.dates[1]),
          apiToken: this.$store.state.storeItems[0].ApiToken,
          storeCloudOid: this.$store.state.storeItems[0].StoreOid,
        },
      })
        .then((res) => res.data)
        .catch((err) => console.log(err));
      this.tableData = response.orders || [];
      this.loading = false;
    },
  },
  methods: {
    formateDate(date) {
      moment.locale('de');
      return moment(date).isSame(moment(), 'day') ? `Heute ${moment(date).format('HH:mm')} Uhr` : `${moment(date).format('DD.MM.YYYY HH:mm')} Uhr`;
    },
    itemRowBackground(item) {
      if (item.deliveryDate) {
        return 'preOrder-row';
      }
      const diff = Math.abs(new Date() - new Date(item.orderDate));
      const minutes = Math.floor(diff / 1000 / 60);
      switch (minutes) {
        case minutes <= 30:
          return 'youngOrder-row';
        case minutes <= 45:
          return 'midAgeOrder-row';
        case minutes <= 120:
          return 'oldOrder-row';
        default:
          return '';
      }
    },
  },
};
</script>
<style>
.preOrder-row {
  color: white;
  background: rgb(115, 93, 142);
  background: linear-gradient(180deg, rgba(115, 93, 142, 1) 0%, rgba(75, 53, 102, 1) 100%);
}
.preOrder-row:hover {
  background-color: #4b3566 !important;
}
.youngOrder-row {
  color: white;
  background-color: #00b0f0;
}
.youngOrder-row:hover {
  background-color: #00b0f0 !important;
}
.midAgeOrder-row {
  color: black;
  background-color: #fffd89;
}
.midAgeOrder-row:hover {
  background-color: #fffd89 !important;
}
.oldOrder-row {
  color: white;
  background-color: #eb3232;
}
.oldOrder-row:hover {
  background-color: #eb3232 !important;
}
.v-data-table-header th {
  white-space: nowrap;
  padding-bottom: 10px !important;
}
</style>
